<template>
  <div class="box1" v-loading="loading" 
    element-loading-spinner="el-icon-loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="content">
      <!-- <div class="box-title">
        <span>马莲滩水厂-水源地水泵瞬时流量</span>
      </div> -->
      <div :id="chartName" class="main"></div>
      <!-- <img class="chart-title" src="../../img/chart_title.png" alt="">
      <img class="chart-bg" src="../../img/chart_bg.png" alt=""> -->
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs'

export default {
  props: {
    chartName: {
      type: String,
      default: 'main1'
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      dataSeries: [],
      timer: null
    };
  },
  methods: {
    dayjs,
    setChart() {
      // this.chartsSize(document.querySelector('.chart-size'),document.getElementById("main1"))
      var myChart = echarts.init(document.getElementById(`${this.chartName}`), 'dark');
      var option = {
        title: {
          subtext:'单位: m³/h'
        },
        backgroundColor: '',
        color: ['#60EEFF'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter:function(params) {
            return params[0].name +'<br>'+params[0].seriesName+': '+params[0].data+'(m³/h)'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          data: ['设备名称1', '设备名称2', '设备名称3', '设备名称4']
        },
        series: [
          {
            name: '工作数据值',
            type: 'bar',
            data: [12,23,35,100],
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            barWidth: 8,
            // label: {
            //   show: true,
            //   position: 'right',
            //   valueAnimation: true
            // },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: '#3977E6' },
                { offset: 1, color: '#37BBF8' }
              ]),
              borderRadius: [0,20,20,0],
            },
          }
        ],
        legend: {
          show: true
        }
      };
      myChart.setOption(option);
      setTimeout(()=>{
        window.onresize = function () {
          myChart.resize();
        }
        window.addEventListener('keydown', function(event) {
          if(event.keyCode===122) {
            myChart.resize();
          }
        })
      },0)
    },
    chartsSize(container, charts) {
      function getStyle(el, name) {
        if(window.getComputedStyle) {
          return window.getComputedStyle(el, null);
        }else {
          return el.currentStyle;
        }
      }
      let hi = getStyle(container, 'height').height;
      charts.style.height = hi;
    },
  },
  mounted() {
    this.setChart()
  },
  destroyed() {

  }
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 200px;
  margin: 20px auto 0;
  z-index: 2;
}
.box-title {
  color: #FFF;
  font-size: 20px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 25px;
  left: 40px;
  z-index: 1;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 40px;
}
.box1 {
  overflow: hidden;
  background-color: #04102c;
  position: relative;
  border-radius: 10px;
  .chart-title {
    width: 93%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 28px auto 0;
    z-index: 0;
  }
  .chart-bg {
    width: 98%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
  }
  .content {
    border-radius: 10px;
    padding-bottom: 16px;
    .search {
      overflow: hidden;
    }
    .box-content {
      padding-bottom: 10px;
      >div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        height: 78px;
        color: #333;
        box-sizing: border-box;
      }
      .imgs {
        width: 48px;
        height: 48px;
        background: rgb(71, 154, 202);
        border-radius: 5px;
        text-align: center;
        line-height: 48px;
        color: #FFF;
        font-size: 24px;
        overflow: hidden;
        position: relative;
      }
      .imgs:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform 0.3s, opacity 0.5s;
      }
      .imgs:active:after {
        transform: scale(0, 0);
        opacity: 0.6;
        transition: 0s;
      }
      .bg-colors1 { background-color: #FFDC60; }
      .bg-colors2 { background-color: #7ED3F4; }
      .bg-colors3 { background-color: #FF915A; }
      .bg-colors4 { background-color: #9FE080; }
      .bg-colors5 { background-color: #FF7070; }
      .bg-colors6 { background-color: #5C7BD9; }
      .title {
        font-size: 14px;
        color: #767779;
      }
      .data {
        font-size: 20px;
        margin-top: 8px;
        color: #202020;
      }
    }
  }
  .date {
    display: flex;
    align-items: center;
    .date-text {
      font-size: 12px;
      font-weight: normal;
      color: #FFF;//#767779
      margin-right: 10px;
      user-select: none;
    }
  }
}
</style>