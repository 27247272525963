<template>
  <div class="box7"
    v-loading="loading" 
    element-loading-spinner="el-icon-loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="content">
      <!-- <div class="box-title">
        马莲滩水厂-水源地水泵总累计流量
        <div class="date">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="统计类别">
              <el-select size="mini" v-model="formInline.cate" placeholder="请选择统计类别" @change="loadData">
                <el-option
                  v-for="item in categorys"
                  :label="item.name"
                  :value="item.code"
                  :key="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div> -->
      <div :id="chartName" class="main"></div>
      <!-- <img class="chart-title" src="../../img/chart_title.png" alt="">
      <img class="chart-bg" src="../../img/chart_bg.png" alt=""> -->
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from "dayjs"
export default {
  props: {
    chartName: {
      type: String,
      default: 'main7'
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      formInline: {
        cate: 'last_seven_day'
      },
      categorys: [
        { code: 'last_seven_day', name: '最近7天' },
        { code: 'last_fifteen_day', name: '最近15天' },
        // { code: 'last_month', name: '月' },
        // { code: 'last_quarter', name: '季' },
        // { code: 'last_year', name: '年' },
      ],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d >= n;
        }
      },
    };
  },
  methods: {
    dayjs,
    setChart() {
      var myChart = echarts.init(document.getElementById(`${this.chartName}`), 'dark');
      var option = {
        title: {
          subtext: '单位: m³/h'
        },
        backgroundColor: '',
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          y: 'top',
          data: ['工作数据值']
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '工作数据值',
            type: 'line',
            // stack: 'Total',
            // areaStyle: {
            //   opacity: 0.1
            // },
            // emphasis: {
            //   focus: 'series'
            // },
            itemStyle: {
              color: '#5AF5EC',
              shadowColor: '#5AF5EC',
            },
            lineStyle: {
              shadowColor: '#5AF5EC',
              shadowBlur: 20,
              color: {
                type: 'linear',
                colorStops: [
                  {offset:0,color:'#5AF5EC'},
                  {offset:1,color:'#5AF5EC'},
                ]
              }
            },
            // smooth: true,
            data: [19,50,30,40,70,30]
          },
          // {
          //   name: '本周',
          //   type: 'line',
          //   // stack: 'Total',
          //   // areaStyle: {
          //   //   opacity: 0.1
          //   // },
          //   // emphasis: {
          //   //   focus: 'series'
          //   // },
          //   itemStyle: {
          //     color: '#FFC332',
          //     shadowColor: '#FFC332',
          //   },
          //   lineStyle: {
          //     shadowColor: '#FFC332',
          //     shadowBlur: 20,
          //     color: {
          //       type: 'linear',
          //       colorStops: [
          //         {offset:0,color:'#FFC332'},
          //         {offset:1,color:'#FFC332'},
          //       ]
          //     }
          //   },
          //   // smooth: true,
          //   data: [19,81,24,26,52,47]
          // }
        ],
      };
      // let xAxisData = []
      // this.data.dataTrends.forEach(v => {
      //   xAxisData.push(v.date)
      // })
      // let x = []
      // for(var i = 0; i < xAxisData.length; i++){
      //   if(x.indexOf(xAxisData[i]) == -1){
      //     x.push(xAxisData[i]);
      //   }
      // }
      // x.sort()
      // option.xAxis.data = x;

      // this.data.dataTrends.forEach(v => {
      //   option.series[0].data.push(v.value)
      // })
      
      myChart.setOption(option);
      setTimeout(()=>{
        window.onresize = function () {
          myChart.resize();
        }
        window.addEventListener('keydown', function(event) {
          if(event.keyCode===122) {
            myChart.resize();
          }
        })
      },0)
    },
  },
  mounted() {
    this.setChart()
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 200px;
  margin: 20px auto 0;
  z-index: 2;
}
.box-title {
  color: #FFF;
  font-size: 20px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 25px;
  left: 40px;
  z-index: 1;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 40px;
}
.box7 {
  overflow: hidden;
  background-color: #04102c;
  position: relative;
  border-radius: 10px;
  // height: 831px;
  .chart-title {
    width: 93%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 28px auto 0;
    z-index: 0;
  }
  .chart-bg {
    width: 98%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
  }
  .content {
    border-radius: 10px;
    padding-bottom: 16px;
  }
  .date {
    display: flex;
    position: absolute;
    right: 10%;
    top: 0;
    z-index: 10;
    .date-text {
      font-size: 12px;
      font-weight: normal;
      color: #FFF;//#767779
      margin-right: 10px;
      user-select: none;
    }
  }
}
.el-form-item {
  margin-bottom: 0;
}
/deep/.el-form-item__content {
  line-height: 50px;
}
/deep/.el-form-item__label {
  font-size: 12px;
  font-weight: normal;
  color: #FFF;//#767779
}
</style>