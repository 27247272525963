<template>
  <div class="box11"
    v-loading="loading" 
    element-loading-spinner="el-icon-loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="content">
      <!-- <div class="box-title">
        马莲滩水厂-水源地水泵总累计流量
        <div class="date">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="统计类别">
              <el-select size="mini" v-model="formInline.cate" placeholder="请选择统计类别" @change="loadData">
                <el-option
                  v-for="item in categorys"
                  :label="item.name"
                  :value="item.code"
                  :key="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div> -->
      <div :id="chartName" class="main"></div>
      <!-- <img class="chart-title" src="../../img/chart_title.png" alt="">
      <img class="chart-bg" src="../../img/chart_bg.png" alt=""> -->
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from "dayjs"
export default {
  props: {
    chartName: {
      type: String,
      default: 'main13'
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      formInline: {
        cate: 'last_seven_day'
      },
      categorys: [
        { code: 'last_seven_day', name: '最近7天' },
        { code: 'last_fifteen_day', name: '最近15天' },
        // { code: 'last_month', name: '月' },
        // { code: 'last_quarter', name: '季' },
        // { code: 'last_year', name: '年' },
      ],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d >= n;
        }
      },
    };
  },
  methods: {
    dayjs,
    setChart() {
      var myChart = echarts.init(document.getElementById(`${this.chartName}`), 'dark');
      var option = {
        title: {
          subtext: '单位: m³/h'
        },
        backgroundColor: '',
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          y: 'top',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: '上月',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 6,
              borderColor: '#100C2A',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 40, name: '数据 1' },
              { value: 38, name: '数据 2' },
              { value: 32, name: '数据 3' },
              { value: 30, name: '数据 4' },
            ]
          }
        ],
      };
      // let xAxisData = []
      // this.data.dataTrends.forEach(v => {
      //   xAxisData.push(v.date)
      // })
      // let x = []
      // for(var i = 0; i < xAxisData.length; i++){
      //   if(x.indexOf(xAxisData[i]) == -1){
      //     x.push(xAxisData[i]);
      //   }
      // }
      // x.sort()
      // option.xAxis.data = x;

      // this.data.dataTrends.forEach(v => {
      //   option.series[0].data.push(v.value)
      // })
      
      myChart.setOption(option);
      setTimeout(()=>{
        window.onresize = function () {
          myChart.resize();
        }
        window.addEventListener('keydown', function(event) {
          if(event.keyCode===122) {
            myChart.resize();
          }
        })
      },0)
    }
  },
  mounted() {
    this.setChart()
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 200px;
  margin: 20px auto 0;
  z-index: 2;
}
.box-title {
  color: #FFF;
  font-size: 20px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 25px;
  left: 40px;
  z-index: 1;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 40px;
}
.box11 {
  overflow: hidden;
  background-color: #04102c;
  position: relative;
  border-radius: 10px;
  // height: 831px;
  .chart-title {
    width: 93%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 28px auto 0;
    z-index: 0;
  }
  .chart-bg {
    width: 98%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
  }
  .content {
    border-radius: 10px;
    padding-bottom: 16px;
  }
  .date {
    display: flex;
    position: absolute;
    right: 10%;
    top: 0;
    z-index: 10;
    .date-text {
      font-size: 12px;
      font-weight: normal;
      color: #FFF;//#767779
      margin-right: 10px;
      user-select: none;
    }
  }
}
.el-form-item {
  margin-bottom: 0;
}
/deep/.el-form-item__content {
  line-height: 50px;
}
/deep/.el-form-item__label {
  font-size: 12px;
  font-weight: normal;
  color: #FFF;//#767779
}
</style>